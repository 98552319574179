import React, { useState, useEffect, useCallback } from "react";
import style from "./MyLearning.module.css";
import { GoXCircle } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import { MdClear } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Loader from "../Loder/Loader";
import { Doughnut } from "react-chartjs-2";
import { BsCheckCircle } from "react-icons/bs";
import useRazorpay from "react-razorpay";
import learnersLOgo from "../../assests/1o1LearnersClub-Logo1-100-×-100px-2-300x300.png";
import { googleLogout } from "@react-oauth/google";
import { encryptJsonData } from "../EncryptionUtils";
import { getCountryCode } from "../CountryDetails";
import { baseurl } from "../Url";
import { AnimationData } from "../../Animation/AnimationData";
import Lottie from "lottie-react";
import { IoPersonCircleOutline } from "react-icons/io5";
import goldMedal from "../../assests/goldnew-removebg-preview.png";
import silverMedal from "../../assests/silvercutnew-removebg-preview.png";
import brownsMedal from "../../assests/brownNew-removebg-preview.png";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";

const MyLearning = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);

  const [cardList, setCardList] = useState(false);
  const [cardListDetail, setCardListDetail] = useState([]);
  const [allCategories, setAllCategories] = useState(false);
  const [aboutExp, setAboutExp] = useState("");
  const [yearExp, setYearExp] = useState("");
  const [profilePopup, setProfilePopup] = useState(false);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [lodaing, setLoading] = useState(true);
  const [pricePopup, setPricePopup] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [clickedLearningId, setClickedLearningId] = useState("");
  const [clickedCategoryID, setClickedCategoryID] = useState("");

  const UserID = localStorage.getItem("USERID");
  const ClubID = localStorage.getItem("ClubID");
  const OrgID = localStorage.getItem("ORGID");
  const key = JSON.parse(localStorage.getItem("access-token"));

  const mentor = localStorage.getItem("mentor");
  const learnertoken = localStorage.getItem("learnertoken");
  const clickedItem = localStorage.getItem("clickedItem");
  const clickedItemDes = localStorage.getItem("clickeditemDes");
  const [token, setToken] = useState(learnertoken ? learnertoken : key);
  const location = useLocation();
  const username = location.state?.username;
  const userid = location.state?.userID;
  const peerRole = location.state?.peerRole;
  const learneruserid = localStorage.getItem("learneruserid");
  const [user_ID, setUser_ID] = useState(
    peerRole !== undefined ? learneruserid : UserID
  );
  const [search, setSearch] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [leaderBoardShow, setLeaderBoardShow] = useState(false);
  const [rewardValues, setRewardValues] = useState([]);
  // const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);

  const CountryDetails = localStorage.getItem("countrydetails");

  const clubIdsArray = ClubID?.split(",");

  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const sessionHandle = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    googleLogout();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    if (CountryDetails === undefined || CountryDetails === null) {
      getCountryCode()
        .then((countryData) => {
          localStorage.setItem("countrydetails", true);
          localStorage.setItem("timezone", countryData.timezone);
          localStorage.setItem("currency", countryData.currency);
          setCurrency(countryData.currency);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [CountryDetails]);

  async function rewardPoint(id) {
    console.log(id);
    await fetch(`${baseurl}/reward/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        club_id: clubIdsArray,
        main_topic_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRewardValues(data.data);
      });
  }

  async function saveCardList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: user_ID }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (
          data.status_code === 401 ||
          data.error === "Signature has expired"
        ) {
          setSessionExpire(true);
        } else {
          setCardListDetail(data.data);
        }
      });
  }
  async function searchModules() {
    const body = {
      org_id: OrgID,
      search: search,
      user_id: UserID,
    };
    console.log(body);
    const newdata = JSON.stringify(body);
    const encryptstring = encryptJsonData(newdata);
    await fetch(`${baseurl}/main/search`, {
      method: "POST",
      body: JSON.stringify({
        keys: encryptstring,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSearchArray(data.data);
        setLoading(false);
        setSearchCompleted(true);
      });
  }
  async function learningGenerate(clickedname, clickeddes) {
    await fetch(`${baseurl}/new/search/learning/mapping`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
        club_id: clubIdsArray,
        org_id: OrgID,
        name: clickedname,
        des: clickeddes,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          saveCardList();
          localStorage.removeItem("clickedItem");
          localStorage.removeItem("clickeditemDes");
        }
      });
  }
  async function priceGet() {
    await fetch(`${baseurl}/fetch/price_list`, {
      method: "Post",
      body: JSON.stringify({
        code: currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setPriceDetails(data);
        }
      });
  }
  async function createOrder(
    amount,
    plan,
    currency,
    clickedcategoryID,
    clickedlearningId
  ) {
    const response = await fetch(`${baseurl}/create-order`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
        amount: amount,
        learning_id: clickedlearningId,
        category_id: clickedcategoryID,
        plan: plan,
        currency: currency,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    });
    const data = await response.json();
    console.log(data);
    return data.data[0];
  }
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    async (amount, plan, currency, clickedcategoryID, clickedlearningId) => {
      const order = await createOrder(
        amount,
        plan,
        currency,
        clickedcategoryID,
        clickedlearningId
      );
      // const prefill={
      //   ...prefilldata
      // }

      const options = {
        key: `${process.env.REACT_APP_RAZORPAY_TEST_KEY}`,
        amount: order.amount,
        currency: order.currency,
        name: "1o1 Learners Club",
        description: "Test Transaction",
        image: learnersLOgo,
        order_id: order.razorpay_order_id,
        handler: async function (response) {
          const data = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
          };

          const result = await fetch(`${baseurl}/verify-payment`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const responseData = await result.json();
          console.log(responseData);
          if (responseData.status === true) {
            setPricePopup(false);
            saveCardList();
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        notes: {
          address: "1o1 Learners Club",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
      });
      rzpay.open();
    },
    [Razorpay]
  );
  useEffect(() => {
    if (search === "") {
      setSearchCompleted(false);
    }
  }, [search]);

  useEffect(() => {
    console.log(details);
  }, [details]);

  useEffect(() => {
    // Update user_ID when peerRole changes
    setUser_ID(peerRole !== undefined ? learneruserid : UserID);
  }, [peerRole]);

  useEffect(() => {
    const hasFunctionBeenCalled = localStorage.getItem("functionCalled");
    if (
      clickedItem !== undefined &&
      clickedItem !== null &&
      hasFunctionBeenCalled !== "true"
    ) {
      learningGenerate(clickedItem, clickedItemDes);
      localStorage.setItem("functionCalled", "true");
    } else {
      saveCardList();
    }
    setCardList(false);
    // priceGet()
  }, [user_ID]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (search !== "") {
        searchModules();
        setLoading(true);
      }
    }
  };
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      {lodaing ? <Loader /> : ""}
      {profilePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setProfilePopup(false);
            }}
          ></div>
          <div className={style.AddressSection}>
            <form action="">
              <div className={style.Formtext}>
                <p>Please enter experience details</p>
              </div>
              <input
                type="text"
                name=""
                id=""
                value={yearExp}
                required
                onChange={(e) => {
                  setYearExp(e.target.value);
                }}
                placeholder="Year Of experience"
              />
              <input
                type="text"
                name=""
                id=""
                value={aboutExp}
                required
                onChange={(e) => {
                  setAboutExp(e.target.value);
                }}
                placeholder="About experience"
              />

              <button type="submit">CONFIRM</button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
      {/* {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {/* {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )} */}
      {pricePopup ? (
        <>
          <div
            onClick={() => {
              setPricePopup(false);
              setClickedCategoryID("");
              setClickedLearningId("");
            }}
            className={style.PriceDivOverlay}
          ></div>
          <div className={style.PriceDiv}>
            {priceDetails.data && priceDetails.data_show ? (
              <div className={style.PriceTable}>
                <table>
                  <thead>
                    <th style={{ backgroundColor: "#c7c6c5" }}>
                      <p className={style.TableHeadTextfirst}> PRICING </p>
                      <p className={style.TableHeadTextsecond}>TABLE</p>
                    </th>
                    {priceDetails.data.map((packageData) => {
                      function getHeaderBackgroundColor(packageData) {
                        if (packageData.PackageName === "Free") {
                          return "#34b7eb";
                        } else if (packageData.PackageName === "Basic") {
                          return "green";
                        } else if (packageData.PackageName === "Professional") {
                          return "#ebc230";
                        } else if (packageData.PackageName === "Advanced") {
                          return "red";
                        } else {
                          return "#c7c6c5";
                        }
                      }
                      function CurrencySymbolDisplay({ currencySymbol }) {
                        return (
                          <span
                            style={{ fontSize: "1rem" }}
                            className={style.PriceAmount}
                          >
                            {currencySymbol}
                          </span>
                        );
                      }
                      return (
                        <th
                          key={packageData.PackageCode}
                          style={{
                            backgroundColor: getHeaderBackgroundColor(
                              packageData
                            ),
                          }}
                        >
                          <p className={style.PriceText}>
                            {packageData.PackageName}
                          </p>
                          <p className={style.PriceAmount}>
                            {" "}
                            <CurrencySymbolDisplay
                              currencySymbol={packageData.FEE[0].CurrencySymbol}
                            />{" "}
                            {packageData.FEE[0].Amount}
                          </p>
                          <span className={style.PriceMonth}>Per Month</span>
                        </th>
                      );
                    })}
                  </thead>
                  <tbody>
                    {priceDetails.data_show.map((feature) => (
                      <tr key={feature.FeatureCode}>
                        <td>{feature.Name}</td>
                        {priceDetails.data.map((packageData) => (
                          <td key={packageData.PackageCode}>
                            {packageData.Features.some(
                              (f) => f.FeatureCode === feature.FeatureCode
                            ) ? (
                              <span style={{ color: "green" }}>
                                <BsCheckCircle />
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                <GoXCircle />
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr
                      style={{ backgroundColor: "#c7c6c5" }}
                      className={style.ButtonRow}
                    >
                      <td></td>
                      {priceDetails.data.map((packageData) => {
                        function getButtonBackgroundColor(packageData) {
                          if (packageData.PackageName === "Free") {
                            return "#34b7eb";
                          } else if (packageData.PackageName === "Basic") {
                            return "green";
                          } else if (
                            packageData.PackageName === "Professional"
                          ) {
                            return "#ebc230";
                          } else if (packageData.PackageName === "Advanced") {
                            return "red";
                          } else {
                            return "#c7c6c5";
                          }
                        }
                        return (
                          <td
                            key={packageData.PackageCode}
                            style={{
                              backgroundColor: getButtonBackgroundColor(
                                packageData
                              ),
                            }}
                          >
                            <button
                              onClick={() => {
                                handlePayment(
                                  packageData.Fee[0].Amount,
                                  packageData.PackageName,
                                  packageData.FEE[0].mode,
                                  clickedCategoryID,
                                  clickedLearningId
                                );
                              }}
                              disabled={packageData.PackageName === "Free"}
                              className={style.TableButton}
                            >
                              Buy Now
                            </button>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Loading data...</p>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <div className={style.LearningDiv}>
        <div className={style.Head}>
          <div className={style.Input}>
            <BiSearch className={style.Icons} />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchArray([]);
              }}
              onFocus={(e) => {
                e.target.setAttribute("data-placeholder", e.target.placeholder);
                e.target.placeholder = "";
              }}
              onBlur={(e) => {
                e.target.placeholder = e.target.getAttribute(
                  "data-placeholder"
                );
              }}
              onKeyDown={handleKeyPress}
              placeholder="what do you want to learn?"
            />
            {search !== "" ? (
              <span
                onClick={() => {
                  setSearch("");
                  setSearchCompleted(false);
                  setSearchArray([]);
                }}
              >
                <MdClear className={style.Icons} />
              </span>
            ) : (
              ""
            )}
          </div>
          {mentor !== "true" ? (
            <div className={style.learningButton}>
              <button
                disabled={search === ""}
                onClick={() => {
                  searchModules();
                  setLoading(true);
                }}
              >
                Search Topics
              </button>
              {peerRole === "Intern" ? (
                ""
              ) : (
                <button
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Course Gallary
                </button>
              )}
            </div>
          ) : (
            <div className={style.learningButton}>
              <button
                onClick={() => {
                  searchModules();
                  setLoading(true);
                }}
              >
                Search Topics
              </button>
            </div>
          )}
        </div>

        {!search ? (
          <div className={style.Grid}>
            {cardListDetail && cardListDetail.length > 0 ? (
              cardListDetail.map((item, index) => (
                <div key={index} className={style.Card}>
                  <div className={style.Cardhead}>
                    <div className={style.CardheadText}>
                      <p>{item.category_details}</p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      const source = peerRole !== undefined ? "support" : "";
                      navigate("/lerning/detail", {
                        state: {
                          learning_detail: item,
                          peerRole: peerRole,
                          source,
                        },
                      });
                      localStorage.setItem("newLearningId", item.learning_id);
                      localStorage.setItem(
                        "adminmapped",
                        item.admin_mapped_lessons
                      );
                    }}
                    className={style.cardData}
                  >
                    <div className={style.mainContent}>
                      <p>{item.category_details}</p>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Goal</div>
                        <div className={style.SecondContent}>: {item.goal}</div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Timeline</div>
                        <div className={style.SecondContent}>
                          : {item.timeline} Month
                        </div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Status</div>
                        <div className={style.SecondContent}>
                          :{" "}
                          <span
                            style={{
                              color:
                                item.Status === "Not Started"
                                  ? "red"
                                  : item.Status === "In Progress"
                                  ? "#f2781b"
                                  : item.Status === "Completed"
                                  ? "green"
                                  : "",
                            }}
                          >
                            {item.Status}
                          </span>
                        </div>
                      </div>
                      {/* <div className={style.lesson}> */}
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>No.of Modules</div>
                        <div className={style.SecondText}>
                          <span style={{ color: "#902ded" }}>
                          {item.Topics.length}
                          </span>
                        </div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstText}>Assessment Done</div>
                        &nbsp;
                        <div className={style.SecondText}>
                          :{" "}
                          <span style={{ color: "#902ded" }}>
                            {item.assessment_done}/{item.total_assessment}
                          </span>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>

                    <div className={style.roundGraph}>
                      <Doughnut
                        data={{
                          labels: ["Completed", "Remaining"],
                          datasets: [
                            {
                              data: [
                                item.assessment_done,
                                item.total_assessment - item.assessment_done,
                              ],
                              backgroundColor: ["#5cab27", "#e0e0e0"],
                            },
                          ],
                        }}
                      />
                      <p>
                        Completion:{" "}
                        {item.total_assessment !== 0
                          ? `${(
                              (item.assessment_done / item.total_assessment) *
                              100
                            ).toFixed(2)}%`
                          : "0%"}
                      </p>
                      <div className={style.flashingButton}>
                        <button
                          className={style.flashing}
                          onClick={(event) => {
                            event.stopPropagation();
                            setLeaderBoardShow(true);
                            rewardPoint(item.Topics[0].topic_id);
                          }}
                        >
                          Leader Board
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>{!lodaing ? "No Learning" : ""}</div>
            )}
          </div>
        ) : (
          <div className={style.SearchGrid}>
            {searchArray && searchArray.length > 0 ? (
              searchArray.map((searchitem, index) => {
                return (
                  <div className={style.SearchCardMain}>
                    {searchitem.mapped_lessons &&
                    searchitem.mapped_lessons.length > 0
                      ? searchitem.mapped_lessons.map((mappeditem) => {
                          return (
                            <div key={index} className={style.SearchCard}>
                              <div className={style.SearchCardHeading}>
                                <p>{mappeditem.name}</p>
                              </div>
                              <div className={style.SearchCardDes}>
                                <p>{mappeditem.des}</p>
                              </div>
                              <div className={style.SearchCardButton}>
                                <button
                                  onClick={() => {
                                    setSearch("");
                                    setSearchArray([]);
                                    setSearchCompleted(false);
                                  }}
                                >
                                  Already in your list
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {searchitem.search_lessons &&
                    searchitem.search_lessons.length > 0
                      ? searchitem.search_lessons.map((search) => {
                          return (
                            <div className={style.SearchCard}>
                              <div className={style.SearchCardHeading}>
                                <p>{search.name}</p>
                              </div>
                              <div className={style.SearchCardDes}>
                                <p>{search.des}</p>
                              </div>
                              <div className={style.SearchCardButton}>
                                <button
                                  onClick={() => {
                                    learningGenerate(search.name, search.des);
                                    setLoading(true);
                                    setSearch("");
                                    setSearchCompleted(false);
                                  }}
                                >
                                  Add to My Learning
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                );
              })
            ) : search && searchCompleted ? (
              <div className={style.ErrorMessage}>
                <p>Something went wrong, Please try again.</p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {leaderBoardShow ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setLeaderBoardShow(false);
            }}
          ></div>
          <div className={style.leaderBoard}>
            <div className={style.boardHeader}>
              <h3>LEADER BOARD</h3>
            </div>
            <div className={style.boardSection}>
              {rewardValues &&
                rewardValues.length > 0 &&
                rewardValues.map((value, i) => {
                  return (
                    <div className={style.boardValues} key={i}>
                      <div className={style.valueBadge}>
                        {i === 0 ? (
                          <img src={goldMedal} alt="" />
                        ) : i === 1 ? (
                          <img src={silverMedal} alt="" />
                        ) : i === 2 ? (
                          <img src={brownsMedal} alt="" />
                        ) : (
                          <p>{i + 1}</p>
                        )}
                      </div>
                      <div className={style.valueAvatar}>
                        <IoPersonCircleOutline />
                      </div>
                      <div className={style.playerNameStar}>
                        <p>{value.name}</p>
                        <p>{value.point}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {sessionExpire ? (
        <div className={style.session}>
          <div className={style.session_close}>
            <AiOutlineClose onClick={() => setSessionExpire(false)} />
          </div>
          <div className={style.session_content}>
            <p>Session is Expired.Please Log In !</p>
          </div>
          <div className={style.session_button}>
            <button
              onClick={() => {
                sessionHandle();
              }}
            >
              Log In
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyLearning;